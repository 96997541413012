<!--
// nuxt-ui/layouts/healthCheck.vue
-->
<script setup lang="ts"></script>

<template>
  <slot></slot>
</template>

<style scoped></style>
